import React, { useState, useEffect } from 'react';
import { Input, Checkbox, Button } from '@nextui-org/react';
import CheckAnimation from './check';
import CopiarDatosModal from './copiar-datos';

const MiFormulario = () => {
  const [nombre, setNombre] = useState('');
  const [email, setEmail] = useState('');
  const [telefono, setTelefono] = useState('');
  const [enviado, setEnviado] = useState(false);
  const [error, setError] = useState({
    nombre: false,
    email: false,
    telefono: false,
  });
  const [aceptado, setAceptado] = useState(false);
  const [nombreEmpresa, setNombreEmpresa] = useState('');
  const [idTemporada, setIdTemporada] = useState('');
  const [loading, setLoading] = useState(false); // Estado para controlar la carga

  const telefonoRegex = /^9\d{8}$/;

  useEffect(() => {
    const urlParts = window.location.pathname.split('/');
    const empresa = urlParts[urlParts.length - 2];
    const temporada = urlParts[urlParts.length - 1];

    setNombreEmpresa(empresa);
    setIdTemporada(temporada);
  }, []);

  const handleEnviar = async () => {
    setError({ nombre: false, email: false, telefono: false });

    let tieneError = false;

    if (!nombre) {
      setError((prev) => ({ ...prev, nombre: true }));
      tieneError = true;
    }

    if (!email) {
      setError((prev) => ({ ...prev, email: true }));
      tieneError = true;
    }

    if (!telefonoRegex.test(telefono)) {
      setError((prev) => ({ ...prev, telefono: true }));
      tieneError = true;
    }

    if (!tieneError) {
      setLoading(true); // Iniciar carga
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/descuento/${nombreEmpresa}/${idTemporada}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ nombre, correo: email, telefono }),
        });

        if (response.ok) {
          setEnviado(true);
        } else {
          console.error('Error al enviar los datos:', response.statusText);
        }
      } catch (error) {
        console.error('Error en la solicitud:', error);
      } finally {
        setLoading(false); // Detener carga
      }
    }
  };

  const handleChangeNombre = (e) => {
    setNombre(e.target.value);
    if (error.nombre && e.target.value) {
      setError((prev) => ({ ...prev, nombre: false }));
    }
  };

  const handleChangeEmail = (e) => {
    setEmail(e.target.value);
    if (error.email && e.target.value) {
      setError((prev) => ({ ...prev, email: false }));
    }
  };

  const handleChangeTelefono = (e) => {
    setTelefono(e.target.value);
    if (error.telefono && e.target.value.match(telefonoRegex)) {
      setError((prev) => ({ ...prev, telefono: false }));
    }
  };

  const handleCheckboxChange = (e) => {
    setAceptado(e.target.checked);
  };

  const copiarAlPortapapeles = () => {
    const datosTransferencia = `Nombre: ${nombre}\nEmail: ${email}\nTeléfono: ${telefono}`;
    navigator.clipboard.writeText(datosTransferencia).then(() => {});
  };

  if (enviado) {
    return (
      <div className='border-t-2 mr-[10px] ml-[10px] mt-[-35px] pt-8 p-[10px]'>
        <h3 className='text-2xl font-semibold text-teal-400 brightness-90'>
          Tu descuento está listo
        </h3>
        <p className='text-[12px]'>
          Te acabamos de enviar el QR con el descuento a través de email y a través de WhatsApp
        </p>
        <div className='relative'>
          <div className='mt-[20%] z-10'>
            <CheckAnimation />
          </div>
          <div className='mt-[30%] z-20'>
            <CopiarDatosModal />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className='border-t-2 mr-[10px] ml-[10px] mt-[-35px] pt-8'>
      <div className='grid w-full flex-wrap md:flex-nowrap gap-4 p-[10px] columns-1 rows-4 max-w-[400px] m-auto'>
        <Input
          type='text'
          label='Nombre Completo'
          value={nombre}
          onChange={handleChangeNombre}
          color={error.nombre ? 'danger' : 'default'}
        />
        <Input
          type='email'
          label='Email'
          value={email}
          onChange={handleChangeEmail}
          color={error.email ? 'danger' : 'default'}
        />
        <Input
          type='text'
          label='Número de Teléfono'
          placeholder='9 4353 2626'
          value={telefono}
          onChange={handleChangeTelefono}
          color={error.telefono ? 'danger' : 'default'}
        />
        <div className='flex justify-between items-center'>
          <Checkbox onChange={handleCheckboxChange}>
            <span className='text-[0.7rem]'>
              Acepta
              <a
                href='https://geometricadigital.cl'
                target='_blank'
                rel='noopener noreferrer'
                className='hover:text-blue-700 font-bold'>
                {' términos y condiciones'}
              </a>
            </span>
          </Checkbox>
          <Button 
            onClick={handleEnviar} 
            disabled={!aceptado} 
            isLoading={loading}
            spinner={
              <svg
                className="animate-spin h-5 w-5 text-current"
                fill="none"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                />
                <path
                  className="opacity-75"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  fill="currentColor"
                />
              </svg>
            }
          >
            ENVIAR
          </Button>
        </div>
      </div>
    </div>
  );
};

export default MiFormulario;

import React, { useState } from 'react';
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Input,
  Select,
  SelectItem,
  ButtonGroup,
  ScrollShadow,
  Autocomplete,
  AutocompleteItem
} from "@nextui-org/react";

// Lista de bancos (puedes reemplazar esto con tus propios datos)
const bancos = [
  { name: "BCI/MACH" },
  { name: "BANCO CONSORCIO" },
  { name: "BANCO DE CHILE-EDWARDS-CREDICHILE" },
  { name: "SCOTIABANK-DESARROLLO" },
  { name: "BANCO BBVA" },
  { name: "BANCOESTADO" },
  { name: "BANCO INTERNACIONAL" },
  { name: "BANCO FALABELLA" },
  { name: "BANCO ITAU" },
  { name: "BANCO RIPLEY/CHEK" },
  { name: "BANCO SANTANDER" },
  { name: "BICE" },
  { name: "COOPEUCH/DALE" },
  { name: "COPEC PAY" },
  { name: "CORP-BANCA" },
  { name: "GLOBAL66" },
  { name: "HSBC BANK" },
  { name: "LA POLAR PREPAGO" },
  { name: "MERCADO PAGO" },
  { name: "PREX" },
  { name: "BANCO SECURITY" },
  { name: "TAPP CAJA LOS ANDES" },
  { name: "PREPAGO TENPO" },
  { name: "PREPAGO LOS HEROES" },
  { name: "MUFG BANK, LTD." },
  { name: "Scotiabank" }
];


const ModalPerfil = ({ isOpen, closeModal }) => {
  // Estado para manejar la selección de botón
  const [selectedSection, setSelectedSection] = useState('informacion-general');

  // Estados para almacenar los valores de los inputs
  const [perfil, setPerfil] = useState({
    fotoPerfil: null,
    banner: null,
    carta: ''
  });

  const [redesSociales, setRedesSociales] = useState({
    whatsapp: '',
    instagram: '',
    facebook: '',
    web: '',
    direccion: '',
    telefonoFijo: '',
    email: ''
  });

  const [datosBancarios, setDatosBancarios] = useState({
    nombre: '',
    rut: '',
    tipoCuenta: '',
    numeroCuenta: '',
    nombreBanco: '',
    emailBanco: ''
  });

  // Funciones para manejar los cambios en los inputs
  const handlePerfilChange = (e) => {
    const { name, value, files } = e.target;
    if (files) {
      setPerfil({ ...perfil, [name]: files[0] });
    } else {
      setPerfil({ ...perfil, [name]: value });
    }
  };

  const handleRedesSocialesChange = (e) => {
    const { name, value } = e.target;
    setRedesSociales({ ...redesSociales, [name]: value });
  };

  const handleDatosBancariosChange = (e) => {
    const { name, value } = e.target;
    setDatosBancarios({ ...datosBancarios, [name]: value });
  };

  const handleTipoCuentaChange = (value) => {
    setDatosBancarios({ ...datosBancarios, tipoCuenta: value });
  };

  const resizeObserverLoopErr = /(ResizeObserver loop limit exceeded)/;

  window.addEventListener('error', (e) => {
    if (resizeObserverLoopErr.test(e.message)) {
      e.stopImmediatePropagation();
    }
  });

  return (
    <Modal isOpen={isOpen} onOpenChange={closeModal} scrollBehavior="inside" size="lg">
      <ModalContent>
        <ModalHeader>Perfil de la empresa</ModalHeader>
        <ModalBody>
          <div className="p-[10px]">
            {/* Menú de botones */}
            <ButtonGroup className='flex items-center mb-6'>
              <Button
                onClick={() => setSelectedSection('informacion-general')}
                className={selectedSection === 'informacion-general' ? 'bg-green-500 text-white font-semibold w-full ml-auto' : 'w-full ml-auto'}
              >
                General
              </Button>
              <Button
                onClick={() => setSelectedSection('redes-sociales')}
                className={selectedSection === 'redes-sociales' ? 'bg-green-500 text-white font-semibold w-full' : 'w-full'}
              >
                Sociales
              </Button>
              <Button
                onClick={() => setSelectedSection('datos-bancarios')}
                className={selectedSection === 'datos-bancarios' ? 'bg-green-500 text-white font-semibold w-full mr-auto' : 'w-full mr-auto'}
              >
                Bancarios
              </Button>
            </ButtonGroup>

            {/* Contenido según la sección seleccionada */}
            {selectedSection === 'informacion-general' && (
              <ScrollShadow className='min-h-[40vh]' hideScrollBar>
                <div className="flex flex-col gap-4">
                  <Input
                    fullWidth
                    type="file"
                    label="Foto de Perfil"
                    name="fotoPerfil"
                    onChange={handlePerfilChange}
                    accept="image/*"
                  />
                  <Input
                    fullWidth
                    type="file"
                    label="Banner"
                    name="banner"
                    onChange={handlePerfilChange}
                    accept="image/*"
                  />
                  <Input
                    fullWidth
                    type="text"
                    label="Carta"
                    placeholder="Ingrese la carta de menú"
                    name="carta"
                    value={perfil.carta}
                    onChange={handlePerfilChange}
                    labelPlacement="outside"
                  />
                </div>
              </ScrollShadow>
            )}

            {selectedSection === 'redes-sociales' && (
              <ScrollShadow className='h-[40vh]' hideScrollBar>
                <div className="flex flex-col gap-4">
                  <Input
                    fullWidth
                    type="text"
                    label="WhatsApp"
                    placeholder="Ingrese su número de WhatsApp"
                    name="whatsapp"
                    value={redesSociales.whatsapp}
                    onChange={handleRedesSocialesChange}
                  />
                  <Input
                    fullWidth
                    type="text"
                    label="Instagram"
                    placeholder="Ingrese su cuenta de Instagram"
                    name="instagram"
                    value={redesSociales.instagram}
                    onChange={handleRedesSocialesChange}
                  />
                  <Input
                    fullWidth
                    type="text"
                    label="Facebook"
                    placeholder="Ingrese su cuenta de Facebook"
                    name="facebook"
                    value={redesSociales.facebook}
                    onChange={handleRedesSocialesChange}
                  />
                  <Input
                    fullWidth
                    type="text"
                    label="Web"
                    placeholder="Ingrese su sitio web"
                    name="web"
                    value={redesSociales.web}
                    onChange={handleRedesSocialesChange}
                  />
                  <Input
                    fullWidth
                    type="text"
                    label="Dirección"
                    placeholder="Ingrese su dirección"
                    name="direccion"
                    value={redesSociales.direccion}
                    onChange={handleRedesSocialesChange}
                  />
                  <Input
                    fullWidth
                    type="text"
                    label="Teléfono Fijo"
                    placeholder="Ingrese su teléfono fijo"
                    name="telefonoFijo"
                    value={redesSociales.telefonoFijo}
                    onChange={handleRedesSocialesChange}
                  />
                  <Input
                    fullWidth
                    type="email"
                    label="Email"
                    placeholder="Ingrese su email"
                    name="email"
                    value={redesSociales.email}
                    onChange={handleRedesSocialesChange}
                  />
                </div>
              </ScrollShadow>
            )}

            {selectedSection === 'datos-bancarios' && (
              <ScrollShadow className='h-[40vh]' hideScrollBar>
                <div className="flex flex-col gap-4">
                  <Input
                    fullWidth
                    type="text"
                    label="Nombre"
                    placeholder="Ingrese el nombre del titular"
                    name="nombre"
                    value={datosBancarios.nombre}
                    onChange={handleDatosBancariosChange}
                  />
                  <Input
                    fullWidth
                    type="text"
                    label="RUT"
                    placeholder="Ingrese el RUT"
                    name="rut"
                    value={datosBancarios.rut}
                    onChange={handleDatosBancariosChange}
                  />
                  <Select
                    label="Tipo de Cuenta"
                    placeholder="Seleccione un tipo de cuenta"
                    name="tipoCuenta"
                    selectedKey={datosBancarios.tipoCuenta}
                    onSelectionChange={handleTipoCuentaChange}
                  >
                    <SelectItem key="cuenta_corriente" value="Cuenta Corriente">
                      Cuenta Corriente
                    </SelectItem>
                    <SelectItem key="cuenta_ahorro" value="Cuenta de Ahorro">
                      Cuenta de Ahorro
                    </SelectItem>
                    <SelectItem key="cuenta_vista" value="Cuenta Vista">
                      Cuenta Vista
                    </SelectItem>
                  </Select>
                  <Input
                    fullWidth
                    type="text"
                    label="Número de Cuenta"
                    placeholder="Ingrese el número de cuenta"
                    name="numeroCuenta"
                    value={datosBancarios.numeroCuenta}
                    onChange={handleDatosBancariosChange}
                  />
                  <Autocomplete
                    defaultItems={bancos}
                    label="Lista de bancos"
                    placeholder="Buscar bancos"
                    className=" w-full"
                  >
                    {(banco) => (
                      <AutocompleteItem key={banco.name} value={banco.name} className=' w-full'>
                        {banco.name}
                      </AutocompleteItem>
                    )}
                  </Autocomplete>
                  <Input
                    fullWidth
                    type="email"
                    label="Email del Banco"
                    placeholder="Ingrese el email registrado en el banco"
                    name="emailBanco"
                    value={datosBancarios.emailBanco}
                    onChange={handleDatosBancariosChange}
                  />
                </div>
              </ScrollShadow>
            )}
          </div>
        </ModalBody>
        <ModalFooter>
          <Button className='bg-green-500 text-white font-semibold'>Guardar</Button>
          <Button onPress={closeModal} color="default">Cerrar</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ModalPerfil;

import React from 'react';
import { Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, Button, useDisclosure } from '@nextui-org/react';

const CopiarDatosModal = () => {
  const { isOpen, onOpen, onOpenChange } = useDisclosure();

  const copiarAlPortapapeles = () => {
    const datos = 'Estos son los datos de transferencia'; // Datos que deseas copiar, esto lo puedes cambiar por un fetch más adelante.
    navigator.clipboard.writeText(datos).then(() => {});
  };

  return (
    <>
      <div className=' '>
      <Button onPress={onOpen} className=' bg-transparent font-semibold z-10'>Copiar datos de transferencia</Button>
      <Modal isOpen={isOpen} onOpenChange={onOpenChange} placement='center' backdrop='opaque'>
        <ModalContent className=' p-[10px]'>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1">Copiar Datos de Transferencia</ModalHeader>
              <ModalBody>
                <p>
                  Aquí puedes copiar los datos de la transferencia haciendo clic en el botón de abajo.
                </p>
              </ModalBody>
              <ModalFooter>
                <Button color="danger" variant="light" onPress={onClose}>
                  Cerrar
                </Button>
                <Button color="primary" onPress={() => { copiarAlPortapapeles(); }}>
                  Copiar
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
      </div>
    </>
  );
};

export default CopiarDatosModal;

// QRScanner.js
import React, { useRef, useEffect, useState } from 'react';
import Webcam from 'react-webcam';
import jsQR from 'jsqr';

const Encargado = () => {
  const [jwtToken, setJwtToken] = useState(localStorage.getItem('jwtToken') || '');
  const webcamRef = useRef(null);
  const [result, setResult] = useState({ Nombre: 'N/A', Descuento: 'N/A' });
  const [showResult, setShowResult] = useState(false);
  const [isScanning, setIsScanning] = useState(true);

  useEffect(() => {
    const scanQRCode = () => {
      if (webcamRef.current) {
        const imageSrc = webcamRef.current.getScreenshot();
        if (!imageSrc) return;

        const img = new Image();
        img.src = imageSrc;

        img.onload = () => {
          const canvas = document.createElement('canvas');
          const context = canvas.getContext('2d');

          canvas.width = img.width;
          canvas.height = img.height;
          context.drawImage(img, 0, 0, img.width, img.height);

          const imageData = context.getImageData(0, 0, canvas.width, canvas.height);
          const code = jsQR(imageData.data, canvas.width, canvas.height);

          if (code) {
            const qrData = code.data;

            if (!qrData.startsWith('http') && !qrData.includes('://')) {
              fetch(process.env.REACT_APP_API_URL+'/scanner', {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                  'Authorization': `Bearer ${jwtToken}`,
                },
                body: JSON.stringify({ IdServicio: qrData }),
              })
                .then((response) => response.json())
                .then((data) => {
                  setResult(data);
                  setShowResult(true);
                  setIsScanning(false);
                })
                .catch((error) => console.error('Error al enviar la solicitud:', error));
            }
          }
        };
      }
    };

    if (isScanning) {
      const interval = setInterval(scanQRCode, 1000);

      return () => clearInterval(interval);
    }
  }, [isScanning, jwtToken]);

  const rango = localStorage.getItem('IdRango');
      if (rango > 3 || rango < 1) {
      return (<div> NOT FOUND</div>);
    }

  const handleBackClick = () => {
    setShowResult(false);
    setIsScanning(true);
  };
  return (
    <div className="h-screen w-full overflow-hidden relative">
      <Webcam
        audio={false}
        ref={webcamRef}
        screenshotFormat="image/jpeg"
        className="absolute inset-0 h-full w-full object-cover"
        videoConstraints={{
          facingMode: 'environment',
        }}
      />

      <div className="absolute inset-0 flex justify-center items-center pointer-events-none">
        <div className="relative w-56 h-56">
          <div className="absolute top-0 left-0 w-8 h-8 border-t-4 border-l-4 border-white"></div>
          <div className="absolute top-0 right-0 w-8 h-8 border-t-4 border-r-4 border-white"></div>
          <div className="absolute bottom-0 left-0 w-8 h-8 border-b-4 border-l-4 border-white"></div>
          <div className="absolute bottom-0 right-0 w-8 h-8 border-b-4 border-r-4 border-white"></div>
          <div className="absolute inset-0 flex items-center justify-center">
            <div className="grid grid-cols-7 grid-rows-7 gap-1">
              {[ '1110111', '1010101', '1110111', '0000000', '1110101', '1010011', '1110110' ]
                .map((row, rowIndex) =>
                  row.split('').map((cell, cellIndex) => (
                    <div
                      key={`${rowIndex}-${cellIndex}`}
                      className={`w-1.5 h-1.5 ${cell === '1' ? 'bg-white' : 'bg-transparent'}`}
                    />
                  ))
                )
              }
            </div>
          </div>
        </div>
      </div>

      <div
        className={`fixed bottom-0 left-0 right-0 rounded-tl-3xl rounded-tr-3xl bg-white p-4 transition-transform duration-300 ${showResult ? 'translate-y-0' : 'translate-y-[85%]'}`}
        style={{ height: '40%' }}
      >
        <p className="font-bold text-xl text-black">INFO DESCUENTO</p>
        <button
          onClick={handleBackClick}
          className="absolute top-14 left-2 text-gray-500"
        >
          {'<----'}
        </button>
        <div className="mt-8 text-center">
          <h2 className="text-xl font-bold">Nombre: {result.Nombre}</h2>
          <p className="text-lg">Descuento: {result.Descuento}%</p>
          <p>DOWNLOAD PDF - próximamente</p>
        </div>
      </div>
    </div>
  );
};

export default Encargado;
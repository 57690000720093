import React, { useState } from 'react';
import { 
  Modal, 
  ModalContent, 
  ModalHeader, 
  ModalBody, 
  ModalFooter, 
  Button, 
  Input, 
  Textarea,
  DatePicker, 
  ScrollShadow
} from "@nextui-org/react";
import { useNavigate } from 'react-router-dom';

const ModalAgregar = ({ isOpen, closeModal }) => {
  const [codigoDescuento, setCodigoDescuento] = useState('');
  const [descripcionProducto, setDescripcionProducto] = useState('');
  const [fechaFin, setFechaFin] = useState(null);
  const [banner, setBanner] = useState(null); 
  const [politicas, setPoliticas] = useState('');
  const [descuento, setDescuento] = useState('');
  const [isLoading, setIsLoading] = useState(false); // Nuevo estado para manejar la carga

  const [errores, setErrores] = useState({
    codigoDescuento: false,
    descripcionProducto: false,
    fechaFin: false,
    banner: false,
    politicas: false,
    descuento: false
  });

  const resetForm = () => {
    setCodigoDescuento('');
    setDescripcionProducto('');
    setFechaFin(null);
    setBanner(null);
    setPoliticas('');
    setDescuento('');
    setErrores({
      codigoDescuento: false,
      descripcionProducto: false,
      fechaFin: false,
      banner: false,
      politicas: false,
      descuento: false
    });
  };

  const handleAgregar = async () => {
    setErrores({
      codigoDescuento: false,
      descripcionProducto: false,
      fechaFin: false,
      banner: false,
      politicas: false,
      descuento: false
    });

    let tieneError = false;

    if (!codigoDescuento) {
      setErrores((prev) => ({ ...prev, codigoDescuento: true }));
      tieneError = true;
    }
    if (!descripcionProducto) {
      setErrores((prev) => ({ ...prev, descripcionProducto: true }));
      tieneError = true;
    }
    if (!fechaFin) {
      setErrores((prev) => ({ ...prev, fechaFin: true }));
      tieneError = true;
    }
    if (!banner) {
      setErrores((prev) => ({ ...prev, banner: true }));
      tieneError = true;
    }
    if (!politicas) {
      setErrores((prev) => ({ ...prev, politicas: true }));
      tieneError = true;
    }
    if (!descuento) {
      setErrores((prev) => ({ ...prev, descuento: true }));
      tieneError = true;
    }

    if (tieneError) {
      return;
    }

    setIsLoading(true); // Mostrar el estado de carga

    const formData = new FormData();
    formData.append('codigodescuento', codigoDescuento);
    formData.append('Descripcion', descripcionProducto);
    formData.append('FechaCaducidad', fechaFin);
    formData.append('banner', banner); 
    formData.append('Politicas', politicas);
    formData.append('Descuento', descuento);

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/new-season`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('jwtToken')}`,
        },
        body: formData
      });
      closeModal();
      if (response.ok) {
        const data = await response.json();
        resetForm();
        setIsLoading(false); // Finalizar estado de carga
        // window.location.reload();
      } else {
        const errorData = await response.json();
        console.error('Error al agregar la promoción:', errorData.message);
        setIsLoading(false); // Finalizar estado de carga
      }
    } catch (error) {
      console.error('Error al enviar la solicitud:', error);
      setIsLoading(false); // Finalizar estado de carga en caso de error
    }
  };

  const handleInputChange = (setter, field) => (e) => {
    setter(e.target.value);
    setErrores((prev) => ({ ...prev, [field]: false }));
  };

  const handleFileChange = (e) => {
    setBanner(e.target.files[0]); 
    setErrores((prev) => ({ ...prev, banner: false }));
  };

  const handleDateChange = (date) => {
    setFechaFin(date);
    setErrores((prev) => ({ ...prev, fechaFin: false }));
  };

  const handleCloseModal = () => {
    resetForm();
    closeModal();
  };

  return (
    <Modal isOpen={isOpen} onOpenChange={handleCloseModal} scrollBehavior="inside">
      <ModalContent>
        <ModalHeader>Crear promoción</ModalHeader>
        <ModalBody>
          <ScrollShadow hideScrollBar className=' h-[70vh]'>
            <form>
              <Input 
                label="Código de descuento" 
                value={codigoDescuento}
                onChange={handleInputChange(setCodigoDescuento, 'codigoDescuento')} 
                color={errores.codigoDescuento ? 'danger' : 'default'} 
                helperText={errores.codigoDescuento ? 'Este campo es obligatorio' : ''}
                className="mb-4"
                placeholder="PROMO25"
              />
              <Input 
                label="Título de descuento:" 
                value={descripcionProducto}
                onChange={handleInputChange(setDescripcionProducto, 'descripcionProducto')} 
                color={errores.descripcionProducto ? 'danger' : 'default'} 
                helperText={errores.descripcionProducto ? 'Este campo es obligatorio' : ''}
                className="mb-4"
                placeholder='Salchipapas medianas'
              />
              <DatePicker 
                label="Fecha de Finalización"
                variant="bordered"
                value={fechaFin}
                onChange={handleDateChange} 
                color="default"
                isInvalid={errores.fechaFin}
                helperText={errores.fechaFin ? 'Este campo es obligatorio' : ''}
                className="mb-4"
              />
              <Input 
                type="file" 
                placeholder='Banner'
                onChange={handleFileChange}
                color={errores.banner ? 'danger' : 'default'}
                helperText={errores.banner ? 'Este campo es obligatorio' : ''}
                className="mb-4"
              />
              <Textarea 
                label="Políticas" 
                value={politicas}
                onChange={handleInputChange(setPoliticas, 'politicas')} 
                maxLength={250} 
                placeholder="La promoción es válida solo en ..."
                color={errores.politicas ? 'danger' : 'default'} 
                helperText={errores.politicas ? 'Este campo es obligatorio' : ''}
                className="mb-4"
              />
              <Input 
                label="% de Descuento" 
                type="number" 
                value={descuento}
                onChange={handleInputChange(setDescuento, 'descuento')} 
                color={errores.descuento ? 'danger' : 'default'} 
                helperText={errores.descuento ? 'Este campo es obligatorio' : ''}
                className="mb-4"
                placeholder='50'
              />
            </form>
          </ScrollShadow>
        </ModalBody>
        <ModalFooter>
          <Button 
            onPress={handleAgregar} 
            color='success' 
            className='text-white font-semibold'
            isLoading={isLoading} // Mostrar el spinner si está cargando
            spinner={ 
              <svg className="animate-spin h-5 w-5 text-current" fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"/>
                <path className="opacity-75" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" fill="currentColor"/>
              </svg>
            }
          >
            Agregar
          </Button>
          <Button onPress={handleCloseModal}>Cerrar</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ModalAgregar;

import { CardBody, CardFooter, CardHeader, Card, Input, Button, Table, TableHeader, TableColumn, TableBody, TableCell, TableRow, ScrollShadow, Switch, Modal, ModalContent, ModalHeader, ModalBody, ModalFooter } from "@nextui-org/react";
import React, { useEffect, useState } from "react";

// Ícono de eliminación
export const DeleteIcon = (props) => (
  <svg
    aria-hidden="true"
    fill="none"
    focusable="false"
    height="1em"
    role="presentation"
    viewBox="0 0 20 20"
    width="1em"
    {...props}
  >
    <path
      d="M17.5 4.98332C14.725 4.70832 11.9333 4.56665 9.15 4.56665C7.5 4.56665 5.85 4.64998 4.2 4.81665L2.5 4.98332"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
    />
    <path
      d="M7.08331 4.14169L7.26665 3.05002C7.39998 2.25835 7.49998 1.66669 8.90831 1.66669H11.0916C12.5 1.66669 12.6083 2.29169 12.7333 3.05835L12.9166 4.14169"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
    />
    <path
      d="M15.7084 7.61664L15.1667 16.0083C15.075 17.3166 15 18.3333 12.675 18.3333H7.32502C5.00002 18.3333 4.92502 17.3166 4.83335 16.0083L4.29169 7.61664"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
    />
    <path
      d="M8.60834 13.75H11.3833"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
    />
    <path
      d="M7.91669 10.4167H12.0834"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
    />
  </svg>
);

const Usuario = () => {
    const [usuarios, setUsuarios] = useState([]);
    const [nombre, setNombre] = useState(""); // Estado para el nombre
    const [password, setPassword] = useState(""); // Estado para la contraseña
    const [loading, setLoading] = useState(false); // Estado para el loading
    const [isModalOpen, setModalOpen] = useState(false); // Estado del modal
    const [usuarioAEliminar, setUsuarioAEliminar] = useState(null); // Usuario a eliminar

    const obtenerUsuarios = async () => {
        try {
            const token = localStorage.getItem("jwtToken");

            const response = await fetch(`${process.env.REACT_APP_API_URL}/usuarios`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });

            if (response.ok) {
                const data = await response.json();
                setUsuarios(data.data);
            } else {
                console.error("Error en la respuesta:", response.statusText);
            }
        } catch (error) {
            console.error("Error al obtener los usuarios:", error);
        }
    };

    const crearUsuario = async () => {
        setLoading(true); // Iniciar carga
        try {
            const token = localStorage.getItem("jwtToken");
            const response = await fetch(`${process.env.REACT_APP_API_URL}/usuarios`, {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ nombre, password }), // Enviar los datos del nuevo usuario
            });

            if (response.ok) {
                await obtenerUsuarios(); // Refrescar la lista de usuarios
                setNombre(""); // Limpiar el campo de nombre
                setPassword(""); // Limpiar el campo de contraseña
            } else {
                console.error("Error al crear el usuario:", response.statusText);
            }
        } catch (error) {
            console.error("Error al crear el usuario:", error);
        } finally {
            setLoading(false); // Detener carga
        }
    };

    const cambiarEstadoUsuario = async (correo, estadoActual) => {
        try {
            const token = localStorage.getItem("jwtToken");
            const nuevoEstado = estadoActual === 1 ? 2 : 1; // Cambiar el estado

            const response = await fetch(`${process.env.REACT_APP_API_URL}/usuarios/updateEstado`, {
                method: 'PUT',
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ Correo: correo }), // Enviar el correo del usuario
            });

            if (response.ok) {
                // Actualizar el estado del usuario en el estado local
                setUsuarios((prevUsuarios) => 
                    prevUsuarios.map((usuario) => 
                        usuario.Correo === correo ? { ...usuario, IdEstadoUsuario: nuevoEstado } : usuario
                    )
                );
            } else {
                console.error("Error al cambiar el estado del usuario:", response.statusText);
            }
        } catch (error) {
            console.error("Error al cambiar el estado del usuario:", error);
        }
    };

    const eliminarUsuario = async () => {
        try {
            const token = localStorage.getItem("jwtToken");
            const response = await fetch(`${process.env.REACT_APP_API_URL}/usuarios/deleteUsuario`, {
                method: 'DELETE',
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ Correo: usuarioAEliminar }), // Enviar el correo del usuario a eliminar
            });

            if (response.ok) {
                // Eliminar el usuario de la lista
                setUsuarios((prevUsuarios) => prevUsuarios.filter(usuario => usuario.Correo !== usuarioAEliminar));
                setModalOpen(false); // Cerrar modal
            } else {
                console.error("Error al eliminar el usuario:", response.statusText);
            }
        } catch (error) {
            console.error("Error al eliminar el usuario:", error);
        }
    };

    useEffect(() => {
        obtenerUsuarios();
    }, []);

    return (
        <div className="p-[10px] mt-[10px] md:grid md:grid-cols-3">
            <div className="md:pr-[10px] md:cols-span-1">
                <Card className="w-full ml-auto mr-auto h-[300px]">
                    <CardHeader className="font-semibold text-xl">
                        Crear cuenta de encargado
                    </CardHeader>
                    <CardBody>
                        <Input 
                            label="Nombre" 
                            placeholder="Pedro chamo" 
                            type="text"
                            value={nombre}
                            onChange={(e) => setNombre(e.target.value)} // Actualizar estado
                        />
                        <Input 
                            label="Contraseña" 
                            placeholder="•••••" 
                            type="password" 
                            className="mt-4"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)} // Actualizar estado
                        />
                    </CardBody>
                    <CardFooter>
                        <Button 
                            className="ml-auto font-semibold text-white" 
                            color="success"
                            onClick={crearUsuario} // Manejar el click
                            isLoading={loading} // Mostrar loading
                        >
                            Crear
                        </Button>
                    </CardFooter>
                </Card>
            </div>
            <div className="pt-[10px] md:pt-0 md:pl-[10px] md:col-span-2">
                <Card className="w-full ml-auto mr-auto h-[300px]">
                    <CardHeader className="font-semibold text-xl">
                        Tabla de usuarios
                    </CardHeader>
                    <CardBody className="mt-[-15px]">
                        <ScrollShadow className="max-h-[265px] p-1" hideScrollBar size={50}>
                            <Table className="mt-[8px] mb-[13px]">
                                <TableHeader>
                                    <TableColumn className="text-center">Nombre</TableColumn>
                                    <TableColumn className="text-center">Correo</TableColumn>
                                    <TableColumn className="text-center">Password</TableColumn>
                                    <TableColumn className="text-center">Estado</TableColumn>
                                    <TableColumn className="text-center w-6">Acciones</TableColumn>
                                </TableHeader>
                                <TableBody emptyContent={"No rows to display."}>
                                    {usuarios.map((usuario, index) => (
                                        <TableRow key={index}>
                                            <TableCell className="text-center">{usuario.Nombre}</TableCell>
                                            <TableCell className="text-center">{usuario.Correo}</TableCell>
                                            <TableCell className="text-center">{usuario.Password}</TableCell>
                                            <TableCell className="text-center flex items-center justify-center">
                                                <Switch 
                                                    size="sm" 
                                                    color="success" 
                                                    isSelected={usuario.IdEstadoUsuario === 1}
                                                    onChange={() => cambiarEstadoUsuario(usuario.Correo, usuario.IdEstadoUsuario)} // Cambiar estado de usuario
                                                />
                                            </TableCell>
                                            <TableCell className="text-center">
                                                <Button 
                                                    onClick={() => {
                                                        setUsuarioAEliminar(usuario.Correo);
                                                        setModalOpen(true);
                                                    }}
                                                    variant="light"
                                                    color="danger"
                                                >
                                                    <DeleteIcon />
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </ScrollShadow>
                    </CardBody>
                    <CardFooter></CardFooter>
                </Card>
            </div>

            {/* Modal para confirmar la eliminación */}
            <Modal isOpen={isModalOpen} onOpenChange={setModalOpen} isDismissable={false}>
                <ModalContent>
                    <ModalHeader className="flex flex-col gap-1">Confirmación de eliminación</ModalHeader>
                    <ModalBody>
                        <p>¿Está seguro de que desea eliminar al usuario con el correo {usuarioAEliminar}?</p>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="danger" variant="light" onPress={() => setModalOpen(false)}>
                            Cancelar
                        </Button>
                        <Button 
                            color="primary" 
                            onPress={eliminarUsuario}
                            isLoading={loading}
                        >
                            Confirmar
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </div>
    );
};

export default Usuario;

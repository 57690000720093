import React, { useState } from 'react';
import { Button, Divider } from '@nextui-org/react';
import Wsp from './components/whatsapp';
const General = () => {
  // Manejar el estado de los componentes seleccionados
  const [Selected, setSelected] = useState(<div>Elige una opción</div>);

  // Verificación de autenticación
  if (localStorage.getItem('IdRango') !== '1' || localStorage.getItem('jwtToken') === null) {
    return <div>NOT FOUND</div>;
  }

  return (
    <div className='grid grid-cols-3 md:grid-cols-5'>
      {/* Sidebar */}
      <div className='col-span-1 bg-slate-900 w-full h-[100vh] p-[10px]'>

        {/* Título con ícono */}
        <div className='grid grid-cols-2 pt-3 mb-4'>
          <div> 
            <img src='https://cdn-icons-png.flaticon.com/512/2760/2760290.png' className='w-10 filter invert ml-auto mr-[10px]' alt="Icon" />
          </div>
          <div className='flex justify-center items-center'>
            <h1 className='text-white text-xl font-semibold mr-auto'>Tools</h1>
          </div>
        </div>

        <Divider className='bg-white' />

        {/* Botones de gestión */}
        <div className='grid grid-cols-1'>
          <Button variant="bordered" className='text-white font-xl font-semibold mt-4'>Empresas</Button>
          <Button variant="bordered" className='text-white font-xl font-semibold mt-2'>Clientes</Button>
          <Button variant="bordered" className='text-white font-xl font-semibold mt-2 mb-4'>Promociones</Button>
        </div>

        <Divider className='bg-white' />

        {/* Botones de ajustes generales */}
        <div className='grid grid-cols-1'>
          <Button variant="bordered" className='text-white font-xl font-semibold mt-4'>Correo</Button>

          {/* Al hacer clic en este botón, mostramos el componente Wsp */}
          <Button
            variant="bordered"
            className='text-white font-xl font-semibold mb-[43vh] mt-2'
            onClick={() => setSelected(<Wsp />)} // Se actualiza el estado para mostrar el componente Wsp
          >
            WhatsApp
          </Button>
        </div>

        <Divider className='bg-white' />

        {/* Botones de Ajustes y Cerrar sesión */}
        <div className='md:grid md:grid-cols-2 md:gap-3'>
          <Button variant="bordered" className='text-white font-xl font-semibold w-full mt-4 mb-2'>Ajustes</Button>
          <Button variant="solid" color="danger" className='text-white font-xl font-semibold w-full md:mt-4'>Cerrar</Button>
        </div>
      </div>

      {/* Área de contenido principal */}
      <div className='col-span-2 md:col-span-4'>
        {Selected}
      </div>
    </div>
  );
};

export default General;

import React, { useState, useEffect } from 'react';
import { Avatar } from '@nextui-org/react';
import CuponDescuento from './descuentos-list';

function DescuentosDisponibles() {
  const [empresa, setEmpresa] = useState(null);

  // Obtener el nombre de la empresa de la URL actual
  const getNombreEmpresaFromUrl = () => {
    const path = window.location.pathname;
    const nombreEmpresa = path.substring(path.lastIndexOf('/') + 1);
    return nombreEmpresa;
  };

  // Fetch de los datos de la empresa
  useEffect(() => {
    const fetchEmpresaData = async () => {
      const nombreEmpresa = getNombreEmpresaFromUrl();
      try {
        const response = await fetch(process.env.REACT_APP_API_URL+`/new-company/${nombreEmpresa}`);
        if (!response.ok) {
          throw new Error('Error al obtener los datos de la empresa');
        }
        const data = await response.json();
        setEmpresa(data);
      } catch (error) {
        console.error('Error fetching empresa data:', error);
      }
    };

    fetchEmpresaData();
  }, []);

  if (!empresa) return null; // No renderiza nada si no hay datos

  return (
    <div>
      <div
        className="h-[150px] bg-cover bg-center mb-[40px]"
        style={{
          backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.2)), url(data:image/jpeg;base64,${empresa.Banner})`,
        }}
      >
        <div className='relative'>
          <Avatar
            src={`data:image/jpeg;base64,${empresa.FotoPerfil}`}
            className="w-[100px] h-[100px] text-large top-[80px] ml-[10px]"
          />
        </div>
        <div className='h-20 w-[100%]'>
          <h3 className='relative text-white font-bold top-[15px] right-[10px] text-[1.5rem] text-right w-auto'>
            {empresa.Nombre}
          </h3>
        </div>
      </div>
      <CuponDescuento/>
      
    </div>
  );
}

export default DescuentosDisponibles;
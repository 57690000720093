import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import Login from './private/login';
import General from './private/Admin/General';
import Empresa from './private/Empresa/Empresa';
import Encargado from './private/Encargado/Encargado';

import HomePage from './public/homepage';
import DescuentosDisponibles from './public/descuentos-visual';
import Descuento from './public/descuento';

const App = () => {
  return (
    <Router>
      <div className='font-sans text-center'>
        <Routes>
          <Route path="/:variable" element={<DescuentosDisponibles />} />
          <Route path="/:variable/:variable2" element={<Descuento />} />
          <Route path="/" element={<HomePage />} />

          <Route path="/login" element={<Login />} />
          <Route path="/admin" element={<General />} />
          <Route path="/empresa" element={<Empresa />} />
          <Route path="/escaner-qr" element={<Encargado />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;

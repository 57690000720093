import React, { useState, useEffect } from 'react';
import { 
  Modal, 
  ModalContent, 
  ModalHeader, 
  ModalBody, 
  ModalFooter, 
  Button, 
  Input, 
  Textarea,
  DatePicker, 
  ScrollShadow
} from "@nextui-org/react";
import { parseAbsoluteToLocal } from "@internationalized/date"; // Importa para manejar fechas

const ModalModificar = ({ isOpen, closeModal, item }) => {
  const [codigoDescuento, setCodigoDescuento] = useState(''); // Código de descuento (IdTemporada)
  const [descripcionProducto, setDescripcionProducto] = useState('');
  const [fechaFin, setFechaFin] = useState(null);
  const [banner, setBanner] = useState(null); 
  const [politicas, setPoliticas] = useState('');
  const [descuento, setDescuento] = useState('');
  const [isLoading, setIsLoading] = useState(false); // Maneja la carga

  const [errores, setErrores] = useState({
    codigoDescuento: false,
    descripcionProducto: false,
    fechaFin: false,
    banner: false,
    politicas: false,
    descuento: false
  });

  // Función para cargar los datos de la temporada por ID
  const cargarDatosTemporada = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/descuento/promo-empresa/${item.IdTemporada}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('jwtToken')}`
        }
      });
      const data = await response.json();

      setCodigoDescuento(data.IdTemporada);
      setDescripcionProducto(data.Descripcion);

      // Convierte la cadena ISO a un objeto de fecha local
      const fechaCaducidad = parseAbsoluteToLocal(data.FechaCaducidad);
      setFechaFin(fechaCaducidad); // Establece la fecha

      setPoliticas(data.Politicas || '');
      setDescuento(data.Descuento);
      setBanner(null); // No cargamos el banner actual, se debe subir uno nuevo si se desea
    } catch (error) {
      console.error('Error al cargar la temporada:', error);
    }
  };

  // Efecto para cargar los datos cuando el modal se abre
  useEffect(() => {
    if (isOpen && item.IdTemporada) {
      cargarDatosTemporada();
    }
  }, [isOpen, item.IdTemporada]);

  // Función para manejar la actualización de la temporada
  const handleActualizar = async () => {
    setErrores({
      codigoDescuento: false,
      descripcionProducto: false,
      fechaFin: false,
      banner: false,
      politicas: false,
      descuento: false
    });

    let tieneError = false;

    if (!descripcionProducto) {
      setErrores((prev) => ({ ...prev, descripcionProducto: true }));
      tieneError = true;
    }
    if (!fechaFin) {
      setErrores((prev) => ({ ...prev, fechaFin: true }));
      tieneError = true;
    }
    if (!politicas) {
      setErrores((prev) => ({ ...prev, politicas: true }));
      tieneError = true;
    }
    if (!descuento) {
      setErrores((prev) => ({ ...prev, descuento: true }));
      tieneError = true;
    }

    if (tieneError) {
      return;
    }

    setIsLoading(true); // Mostrar el estado de carga

    const formData = new FormData();
    formData.append('Descripcion', descripcionProducto);
    formData.append('FechaCaducidad', fechaFin.toString()); // Asegúrate de que esto sea el formato adecuado
    formData.append('banner', banner); 
    formData.append('Politicas', politicas);
    formData.append('Descuento', descuento);

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/new-season/${codigoDescuento}`, {
        method: 'PUT',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('jwtToken')}`,
        },
        body: formData
      });

      if (response.ok) {
        const data = await response.json();
        resetForm();
        closeModal();
        setIsLoading(false); // Finalizar estado de carga
        // Actualizar la página o los datos
      } else {
        const errorData = await response.json();
        console.error('Error al actualizar la promoción:', errorData.message);
        setIsLoading(false); // Finalizar estado de carga
      }
    } catch (error) {
      console.error('Error al enviar la solicitud:', error);
      setIsLoading(false); // Finalizar estado de carga en caso de error
    }
  };

  // Función para manejar cambios en el formulario
  const handleInputChange = (setter, field) => (e) => {
    setter(e.target.value);
    setErrores((prev) => ({ ...prev, [field]: false }));
  };

  const handleFileChange = (e) => {
    setBanner(e.target.files[0]); 
    setErrores((prev) => ({ ...prev, banner: false }));
  };

  const handleDateChange = (date) => {
    setFechaFin(date);
    setErrores((prev) => ({ ...prev, fechaFin: false }));
  };

  const resetForm = () => {
    setDescripcionProducto('');
    setFechaFin(null);
    setBanner(null);
    setPoliticas('');
    setDescuento('');
  };

  return (
    <Modal isOpen={isOpen} onOpenChange={closeModal} scrollBehavior="inside">
      <ModalContent>
        <ModalHeader>Actualizar Promoción</ModalHeader>
        <ModalBody>
          <ScrollShadow hideScrollBar className='h-[70vh]'>
            <form>
              <Input 
                label="Código de descuento" 
                value={codigoDescuento}
                disabled // Campo deshabilitado
                color="default"
                className="mb-4"
              />
              <Input 
                label="Título de descuento" 
                value={descripcionProducto}
                onChange={handleInputChange(setDescripcionProducto, 'descripcionProducto')} 
                color={errores.descripcionProducto ? 'danger' : 'default'} 
                helperText={errores.descripcionProducto ? 'Este campo es obligatorio' : ''}
                className="mb-4"
              />
              <DatePicker 
                label="Fecha de Finalización"
                className="max-w-[284px] mb-4"
                value={fechaFin} 
                onChange={handleDateChange} 
                description="Selecciona la fecha de finalización."
              />
              <Input 
                type="file" 
                placeholder='Banner'
                onChange={handleFileChange}
                color={errores.banner ? 'danger' : 'default'}
                helperText={errores.banner ? 'Este campo es obligatorio' : ''}
                className="mb-4"
              />
              <Textarea 
                label="Políticas" 
                value={politicas}
                onChange={handleInputChange(setPoliticas, 'politicas')} 
                maxLength={250} 
                color={errores.politicas ? 'danger' : 'default'} 
                helperText={errores.politicas ? 'Este campo es obligatorio' : ''}
                className="mb-4"
              />
              <Input 
                label="% de Descuento" 
                type="number" 
                value={descuento}
                onChange={handleInputChange(setDescuento, 'descuento')} 
                color={errores.descuento ? 'danger' : 'default'} 
                helperText={errores.descuento ? 'Este campo es obligatorio' : ''}
                className="mb-4"
              />
            </form>
          </ScrollShadow>
        </ModalBody>
        <ModalFooter>
          <Button 
            onPress={handleActualizar} 
            color='success' 
            className='text-white font-semibold'
            isLoading={isLoading} // Mostrar el spinner si está cargando
          >
            Actualizar
          </Button>
          <Button onPress={closeModal}>Cerrar</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ModalModificar;

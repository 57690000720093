import React from 'react';
import { Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, Button } from '@nextui-org/react';
import Eliminar from './eliminar';

const ModalEliminar = ({ isOpen, onClose, item }) => {

  return (
    <Modal isOpen={isOpen} onOpenChange={onClose}>
      <ModalContent>
        <ModalHeader>Eliminar promoción?</ModalHeader>
        <ModalBody>
          {"Temporada: "+item.IdTemporada}<br/>
          {"Titulo: "+item.Descripcion}
        </ModalBody>
        <ModalFooter>
          <Button onPress={onClose}>Cancelar</Button>
          <Button color="danger">Eliminar</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ModalEliminar;

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // Importar useNavigate
import { Card, CardHeader, CardBody, Divider, Avatar, Button } from '@nextui-org/react';

const HomePage = () => {
  const [empresas, setEmpresas] = useState([]);
  const navigate = useNavigate(); // Obtener la función navigate

  // Fetch de los datos de las empresas y sus descuentos desde la API
  useEffect(() => {
    const fetchEmpresas = async () => {
      try {
        const response = await fetch(process.env.REACT_APP_API_URL+'/descuento');
        const data = await response.json();
        setEmpresas(data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchEmpresas();
  }, []);

  // Función para manejar la navegación
  const handleCardClick = (nombre) => {
    navigate(`/${nombre}`);
  };

  return (
    <div>
      <header className='bg-slate-400 w-[100%] h-[60px] rounded-bl-md rounded-br-md grid grid-cols-2 grid-rows-1 p-[10px]'>
        <div>
          <h1 className='text-left text-xl font-sans font-bold'>QRDescto</h1>
        </div>
        <div className='ml-auto'>
          <Button color='primary' onClick={() => navigate("/login")}>
            ACCESO EMPRESAS
          </Button>
        </div>
      </header>

      <div className='max-w-[90%] ml-auto mr-auto mt-[30px] '>
        {empresas.map((empresa) => (
          <Card className='mb-[20px] cursor-pointer' >
            <CardHeader onClick={() => handleCardClick(empresa.Nombre)}>
              <Avatar
                src={`data:image/jpeg;base64,${empresa.FotoPerfil}`}
                className="w-[50px] h-[50px]"
              />
              <p className='text-[25px] ml-[15px]'>{empresa.Nombre}</p>
            </CardHeader>
            <Divider />
            <CardBody onClick={() => handleCardClick(empresa.Nombre)} className="pt-[15px]">
    <h2 className="mb-[10px] text-gray-600 text-lg">Descuentos disponibles: <span className="font-bold">{empresa.DescuentosDisponibles}</span></h2>
    
    <h3 className="text-sm text-gray-500 text-center mb-[15px] uppercase tracking-wider">Descuento más grande</h3>
    
    <div className="bg-gray-100 p-[20px] rounded-lg grid grid-cols-3 grid-rows-1 shadow-sm">
      <div className=' w-[120%]'>
        <p className="text-sm font-semibold text-gray-700">Descripción:</p>
        <p className="text-base text-gray-800">{empresa.DescripcionDescuentoMaximo}</p>
      </div>
      <div className=' text-center'>
        <p className="text-sm font-semibold text-gray-700">%</p>
        <p className="text-base text-green-500 font-bold">{empresa.DescuentoMaximo}%</p>
      </div>
      <div>
        <p className="text-sm font-semibold text-gray-700">Días restantes:</p>
        <p className="text-base text-gray-800">{empresa.DiasHastaCaducidad} días</p>
      </div>
    </div>
  </CardBody>
          </Card>
        ))}
      </div>
    </div>
  );
};

export default HomePage;
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Input, Button } from '@nextui-org/react';

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false); // Nuevo estado para la carga
  const navigate = useNavigate();

  const handleLogin = async (event) => {
    event.preventDefault();
    setLoading(true); // Activar el spinner

    try {
      const response = await fetch(process.env.REACT_APP_API_URL + '/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ correo: username, password }),
      });

      if (response.ok) {
        const result = await response.json();

        // Almacenar el token y los datos de usuario/empresa en localStorage
        localStorage.setItem('jwtToken', result.token);
        localStorage.setItem('NombreEmpresa', result.NombreEmpresa);
        localStorage.setItem('NombreUsuario', result.NombreUsuario);
        localStorage.setItem('IdRango', result.IdRango);

        // Redireccionar a la ruta correspondiente
        if (result.IdRango === 3) {
          navigate('/escaner-qr');
        }
        if (result.IdRango === 2) {
          navigate('/empresa');
        }
        if (result.IdRango === 1) {
          navigate('/admin');
        }
      } else {
        const result = await response.json();
        setError(result.bad ? 'Credenciales inválidas' : 'Error en el servidor');
      }
    } catch {
      setError('Error al conectar con el servidor');
    } finally {
      setLoading(false); // Desactivar el spinner
    }
  };

  return (
    <div className='p-4'>
      <div className='grid grid-rows-1 grid-cols-1 mb-6'>
        <a className='mr-auto' href='/'>{"<"}</a>
      </div>
      <div className='p-[10px] sm:max-w-[400px] m-auto md:bg-slate-100 md:rounded-xl md:p-[40px]'>
        <h1 className='text-xl font-semibold mb-6 text-left pl-[10px]'>INICIAR SESION</h1>
        <form onSubmit={handleLogin} className='flex flex-col items-center'>
          <Input
            type="text"
            id="username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            placeholder="CORREO"
            required
            fullWidth
            clearable
            bordered
            autoComplete='off'
            className="mb-4 bg-transparent md:border-2 md:rounded-xl"
          />
          <Input
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="CONTRASEÑA"
            required
            clearable
            autoComplete='off'
            className="mb-4 bg-transparent md:border-2 md:rounded-xl"
          />
          <Button 
            type="submit" 
            className='p-2 bg-blue-500 text-white rounded-md mt-4' 
            isLoading={loading} // Propiedad para mostrar el spinner
            spinner={
              <svg
                className="animate-spin h-5 w-5 text-current"
                fill="none"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                />
                <path
                  className="opacity-75"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  fill="currentColor"
                />
              </svg>
            }
          >
            Login
          </Button>
          {error && <p className='mt-2 text-red-500'>Correo o contraseña incorrectos, reintente</p>}
        </form>
      </div>
    </div>
  );
};

export default Login;

import React, { useState, useEffect } from 'react';
import { Card, CardHeader, CardBody } from '@nextui-org/react';
import { useNavigate } from 'react-router-dom';

const CuponDescuento = () => {
    const [empresa, setEmpresa] = useState([]);
    const navigate = useNavigate(); // Hook para navegación

    // Obtener el nombre de la empresa de la URL actual
    const getNombreEmpresaFromUrl = () => {
        const path = window.location.pathname;
        const nombreEmpresa = path.substring(path.lastIndexOf('/') + 1);
        return nombreEmpresa;
    };

    // Fetch de los datos de descuentos
    useEffect(() => {
        const fetchEmpresaData = async () => {
            const nombreEmpresa = getNombreEmpresaFromUrl();
            try {
                const response = await fetch(process.env.REACT_APP_API_URL+`/descuento/${nombreEmpresa}`);
                if (!response.ok) {
                    throw new Error('Error al obtener los datos de la empresa');
                }
                const data = await response.json();
                setEmpresa(data);
            } catch (error) {
                console.error('Error fetching empresa data:', error);
            }
        };

        fetchEmpresaData();
    }, []);

    if (empresa.length === 0) return null;

    return (
        <div className='p-[10px] lg:grid lg:grid-cols-3'>
            {empresa.map((item, index) => (
                <Card 
                    key={index} 
                    className='h-[150px] overflow-hidden lg:max-w-[400px] relative mb-[20px] cursor-pointer'
                >
                    <CardHeader 
                        className='border-b-2 border-gray cursor-pointer'
                        onClick={() => navigate(`/${getNombreEmpresaFromUrl()}/${item.IdTemporada}`)}
                    >
                        <div className='pb-1'>
                            <h2 className='text-[1.15rem] font-bold'>CUPÓN DE DESCUENTO</h2>
                            <h3 className='text-[0.7rem] text-left absolute top-[35px]'>VÁLIDO HASTA {item.FechaCaducidad.split('T',1)}</h3>
                        </div>
                    </CardHeader>
                    <CardBody className='mt-[-5px]' onClick={() => navigate(`/${getNombreEmpresaFromUrl()}/${item.IdTemporada}`)}>
                        <h3 className='max-w-[60%] text-[0.8rem]'>ESTE CUPÓN SOLO ES APLICABLE EN {item.Nombre}</h3>
                        <h3 className='max-w-[60%] text-[0.8rem]'>APLICABLE A {item.Descripcion}</h3>
                    </CardBody >
                    <div 
                        style={{
                            backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.2)),url(data:image/jpeg;base64,${item.Banner})`,
                        }}
                        className='absolute top-[20%] left-[60%] rounded-[50%] h-[170px] w-[200px] bg-cover bg-center z-50'
                        onClick={() => navigate(`/${getNombreEmpresaFromUrl()}/${item.IdTemporada}`)}
                    >
                        <div className='absolute top-[50px] right-[50px]'>
                            <h3 className='relative top-[20px] right-[10px] text-[2rem] font-bold text-white text-right'>{item.Descuento}%</h3>
                        </div>
                    </div>
                </Card>
            ))}
        </div>
    );
}

export default CuponDescuento;

import { useEffect, useState, React } from 'react';
import { Modal, ModalContent, ModalHeader, ModalBody, Avatar, Input, Checkbox, ScrollShadow, Button } from '@nextui-org/react';

const ModalVer = ({ isOpen, onClose, item }) => {
  const [empresa, setEmpresa] = useState(null);

  useEffect(() => {
    const fetchEmpresaData = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/descuento/promo-empresa/${item.IdTemporada}`);
        if (!response.ok) {
          throw new Error('Error al obtener los datos de la empresa');
        }
        const data = await response.json();
        setEmpresa(data);
      } catch (error) {
        console.error('Error fetching empresa data:', error);
      }
    };

    if (item && isOpen) {
      fetchEmpresaData();
    }

    // Limpiar los datos cuando se cierre el modal
    if (!isOpen) {
      setEmpresa(null);
    }
  }, [item, isOpen]);

  // Condicional para el banner de la empresa
  const bannerStyle = empresa?.Banner
    ? { backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.2)), url(data:image/jpeg;base64,${empresa.Banner})` }
    : {};

  if (!empresa) return null;

  return (
    <Modal isOpen={isOpen} onOpenChange={onClose} size="md" scrollBehavior="inside">
      <ModalContent>
        <ModalHeader>Detalles del Producto</ModalHeader>
        <ModalBody>
          <ScrollShadow hideScrollBar>
            <div className='border-2 rounded-lg mt-2'>
              <div className="h-[120px] bg-cover bg-center mb-[40px] rounded-tl-lg rounded-tr-lg" style={bannerStyle}>
                <div className='relative'>
                  <Avatar
                    src={`data:image/jpeg;base64,${empresa.FotoPerfil}`}
                    className="w-[100px] h-[100px] text-large top-[40px] ml-[10px]"
                  />
                </div>
              </div>
              <div className='grid grid-cols-2 grid-rows-2 pl-4 pr-4'>
                <div>
                  <h2 className='text-left text-[0.8rem] text-gray-400 font-bold'>{empresa.NombreEmpresa}</h2>
                </div>
                <div></div>
                <div className='text-left relative top-[-50px]'>
                  <div>
                    <h3 className='text-[2rem] font-bold'>{empresa.Descuento}% DCTO</h3>
                    <h4 className='font-medium relative top-[-10px]'>{empresa.Descripcion}</h4>
                  </div>
                </div>
                <div className='text-right relative top-[-25px]'>
                  <div>
                    <h3 className='text-[0.7rem] font-bold text-gray-400'>VALIDO HASTA</h3>
                    <h4 className='font-bold relative top-[-5px] text-[1rem]'>{empresa.FechaCaducidad.split('T', 1)}</h4>
                  </div>
                </div>
              </div>
              <div className='border-t-2 mr-[10px] ml-[10px] mt-[-35px] pt-8'>
                <div className='grid w-full flex-wrap md:flex-nowrap gap-4 p-[10px] columns-1 rows-4 max-w-[400px] m-auto'>
                  <Input
                    type='text'
                    label='Nombre Completo'
                    value='' // Valor vacío
                    readOnly // Campo de solo lectura
                  />
                  <Input
                    type='email'
                    label='Email'
                    value='' // Valor vacío
                    readOnly // Campo de solo lectura
                  />
                  <Input
                    type='text'
                    label='Número de Teléfono'
                    placeholder='9 4353 2626'
                    value='' // Valor vacío
                    readOnly // Campo de solo lectura
                  />
                  <div className='flex justify-between items-center'>
                    <Checkbox isSelected={false} isDisabled>
                      <span className='text-[0.7rem]'>
                        Acepta
                        <a href='#' className='hover:text-blue-700 font-bold'>
                          {' términos y condiciones'}
                        </a>
                      </span>
                    </Checkbox>
                    <Button disabled>
                      ENVIAR
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </ScrollShadow>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ModalVer;

import React, { useState, useEffect } from 'react';

const CheckAnimation = () => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isCompleted, setIsCompleted] = useState(false);

  useEffect(() => {
    // Iniciar la expansión
    setIsExpanded(true);
    // Programar para que se reduzca después de la expansión
    const timeout = setTimeout(() => {
      setIsExpanded(false);
      setIsCompleted(true); // Mostrar el check
    }, 1000); // Duración de la expansión

    return () => clearTimeout(timeout); // Limpiar el timeout
  }, []);

  return (
    <div className="relative z-50 m-auto flex items-center justify-center">
      <div className={`flex items-center justify-center w-24 h-24 rounded-full bg-green-500 transform transition-all duration-[1000ms] ease-soft-spring ${isExpanded ? 'scale-[12]' : 'scale-100'}`}>
        <div className={`w-10 h-5 border-b-8 border-l-8 border-white transform -rotate-45 transition-opacity duration-500 ${isCompleted ? 'opacity-100' : 'opacity-0'}`}>
        </div>
      </div>
    </div>
  );
};

export default CheckAnimation;

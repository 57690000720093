import React, { useState,useEffect} from 'react';
import { Avatar } from '@nextui-org/react';
import MiFormulario from './descuento-form'

function QRCodeGenerator() {
  const [empresa, setEmpresa] = useState(null);

  // Obtener el nombre de la empresa de la URL actual
  const getNombreEmpresaFromUrl = () => {
    const path = window.location.pathname;
    const nombreEmpresa = path.substring(path.lastIndexOf('/')+1);
    return nombreEmpresa;
  };

  // Fetch de los datos de la empresa
  useEffect(() => {
    const fetchEmpresaData = async () => {
      const nombreEmpresa = getNombreEmpresaFromUrl();
      try {
        const response = await fetch(process.env.REACT_APP_API_URL+`/descuento/promo/${nombreEmpresa}`);
        if (!response.ok) {
          throw new Error('Error al obtener los datos de la empresa');
        }
        const data = await response.json();
        setEmpresa(data);
      } catch (error) {
        console.error('Error fetching empresa data:', error);
      }
    };
    fetchEmpresaData();
  }, []);
  if (!empresa) return null;
  return (
    <div className=''>
      <div
        className="h-[150px] bg-cover bg-center mb-[40px]"
        style={{
          backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.2)), url(data:image/jpeg;base64,${empresa.Banner})`,
        }}
      >
        <div className='relative'>
          <Avatar
            src={`data:image/jpeg;base64,${empresa.FotoPerfil}`}
            className="w-[100px] h-[100px] text-large top-[80px] ml-[10px]"
          />
        </div>
      </div>
      <div className=' grid grid-cols-2 grid-rows-2 pl-4 pr-4'>
        <div className=''>
          <h2 className=' text-left text-[0.8rem] text-gray-400 font-bold'>{empresa.NombreEmpresa}</h2>
        </div>
        <div>

        </div>
        <div className=' text-left relative top-[-50px]'>
          <div className=''>
            <h3 className=' text-[2rem] font-bold'>{empresa.Descuento}%DCTO</h3>
            <h4 className=' font-medium relative top-[-10px]'>{empresa.Descripcion}</h4>
          </div>
        </div>
        <div className=' text-right relative top-[-25px]'>
          <div className=''>
            <h3 className=' text-[0.7rem] font-bold text-gray-400'>VALIDO HASTA</h3>
            <h4 className=' font-bold relative top-[-5px] text-[1rem]'>{empresa.FechaCaducidad.split('T',1)}</h4>
          </div>
        </div>
      </div>
        <div>
          <MiFormulario/>
        </div>
    </div>
  );
}

export default QRCodeGenerator;

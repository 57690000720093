import React, { useState, useCallback } from 'react';
import { Table, TableHeader, TableColumn, TableRow, TableCell, TableBody, ScrollShadow, Card, CardHeader, CardBody, CardFooter } from '@nextui-org/react';
import { DeleteIcon } from './jsx/DeleteIcon';
import { EditIcon } from './jsx/EditIcon';
import { EyeIcon } from './jsx/EyeIcon';
import ModalVer from './ver';
import ModalModificar from './modificar';
import ModalEliminar from './eliminar';

const ProductTable = ({ data }) => {
  const [isViewOpen, setViewOpen] = useState(false);
  const [isEditOpen, setEditOpen] = useState(false);
  const [isDeleteOpen, setDeleteOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  
  // Estado para manejar la ordenación
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });

  const openViewModal = (item) => {
    setSelectedItem(item);
    setViewOpen(true);
  };

  const openEditModal = (item) => {
    setSelectedItem(item);
    setEditOpen(true);
  };

  const openDeleteModal = (item) => {
    setSelectedItem(item);
    setDeleteOpen(true);
  };

  const closeViewModal = () => setViewOpen(false);
  const closeEditModal = () => setEditOpen(false);
  const closeDeleteModal = () => setDeleteOpen(false);

  // Función para manejar la ordenación
  const handleSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  // Función para ordenar los datos
  const sortedData = React.useMemo(() => {
    let sortableItems = [...data];
    if (sortConfig.key !== null) {
      sortableItems.sort((a, b) => {
        const first = a[sortConfig.key];
        const second = b[sortConfig.key];

        if (first < second) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (first > second) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableItems;
  }, [data, sortConfig]);

  const columns = [
    { name: "Descripción", uid: "Descripcion" },
    { name: "% DESCUENTO", uid: "Descuento" },
    { name: "Días restantes", uid: "DiasRestantes" },
    { name: "Total creados", uid: "TotalCreados" },
    { name: "Total canjeados", uid: "TotalCanjeados" },
    { name: "Acciones", uid: "actions" },
  ];

  const renderCell = useCallback((item, columnKey) => {
    const cellValue = item[columnKey];
    switch (columnKey) {
      case "Descripcion":
        return <p className=''>{cellValue}</p>;
      case "Descuento":
      case "DiasRestantes":
      case "TotalCreados":
      case "TotalCanjeados":
        return <p className='text-center'>{cellValue}</p>;
      case "actions":
        return (
          <div className="relative flex items-center justify-center gap-2">
            <span className={ item.IdEstado===2 ? "text-lg text-default-400 cursor-pointer brightness-200" : "text-lg text-default-400 cursor-pointer hover:opacity-50"} onClick={() => openViewModal(item)}>
              <EyeIcon />
            </span>
            <span className={ item.IdEstado===2 ? " text-lg text-default-400 cursor-pointer brightness-200" : "text-lg text-default-400 cursor-pointer hover:opacity-50"} onClick={() => openEditModal(item)}>
              <EditIcon />
            </span>
            <span className={ item.IdEstado===2 ? " text-lg text-default-400 cursor-pointer brightness-200" : "text-lg text-default-400 cursor-pointer hover:opacity-50"} onClick={() => openDeleteModal(item)}>
              <DeleteIcon />
            </span>
          </div>
        );
      default:
        return cellValue;
    }
  }, []);

  return (
    <>
      <div className=' p-[10px] pb-0'>
      <Card>
        <CardHeader>
          <h2 className=' text-xl font-semibold '>
            Tabla de promociones
          </h2>
        </CardHeader>
        <CardBody className=' mt-[-15px] '>
        <ScrollShadow hideScrollBar className=' max-h-[315px]'>
      <Table aria-label="Tabla de Productos" css={{ height: "auto", minWidth: "100%" }} className='p-[10px] mb-[10px]'>
        <TableHeader>
          {columns.map((column) => (
            <TableColumn
              key={column.uid}
              className={column.uid === "Descripcion" ? "" : " text-center"}
              onClick={() => handleSort(column.uid)}  // Manejamos la ordenación manualmente
            >
              {column.name}
            </TableColumn>
          ))}
        </TableHeader>
        <TableBody>
          {sortedData.map((item, index) => (
            <TableRow key={item.Descripcion || index} className={item.IdEstado===2 ? "bg-red-500 bg-opacity-40 text-white " : "rounded-3xl"}>
              {columns.map((column) => (
                <TableCell key={column.uid}>{renderCell(item, column.uid)}</TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
      </ScrollShadow>
        </CardBody>
        <CardFooter>

        </CardFooter>
      </Card>
      </div>

      {/* Modal para Ver */}
      {selectedItem && (
        <ModalVer isOpen={isViewOpen} onClose={closeViewModal} item={selectedItem} />
      )}

      {/* Modal para Modificar */}
      {selectedItem && (
        <ModalModificar isOpen={isEditOpen} onClose={closeEditModal} item={selectedItem} />
      )}

      {/* Modal para Eliminar */}
      {selectedItem && (
        <ModalEliminar isOpen={isDeleteOpen} onClose={closeDeleteModal} item={selectedItem} />
      )}
    </>
  );
};

export default ProductTable;

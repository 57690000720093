import React, { useState, useEffect } from 'react';
import ModalPerfil from './Perfil'; // Modal para el perfil
import ModalAgregar from './components/agregar'; // Modal para agregar producto
import ProductTable from './components/table'; // Ajusta la ruta según tu estructura de carpetas
import { Avatar, ScrollShadow } from "@nextui-org/react";
import Usuario from './components/usuarios';

const Empresa = () => {
  const rango = localStorage.getItem('IdRango');
  
  const [productos, setProductos] = useState([]);
  const [empresa, setEmpresa] = useState({
    nombre: '',
    fotoPerfil: '/default-avatar.png', // Imagen por defecto
    banner: '/default-banner.jpg' // Imagen por defecto
  });

  // Estados para controlar la apertura de los modales
  const [isPerfilModalOpen, setPerfilModalOpen] = useState(false);
  const [isAgregarModalOpen, setAgregarModalOpen] = useState(false);

  // Estado para manejar la actualización de la tabla de productos
  const [refreshTable, setRefreshTable] = useState(false);

  // Obtener los datos de la empresa
  useEffect(() => {
    const jwtToken = localStorage.getItem('jwtToken');
    fetch(`${process.env.REACT_APP_API_URL}/new-company`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${jwtToken}`, // Incluir el token en los encabezados
        'Content-Type': 'application/json'
      }
    })
    .then(response => {
      if (!response.ok) throw new Error('Error en la solicitud');
      return response.json();
    })
    .then(data => {
      setEmpresa({
        nombre: data.nombre,
        fotoPerfil: data.fotoPerfil ? `data:image/png;base64,${data.fotoPerfil}` : '/default-avatar.png',
        banner: data.banner ? `data:image/jpeg;base64,${data.banner}` : '/default-banner.jpg'
      });
    })
    .catch(error => console.error('Error fetching company data:', error));
  }, []);

  // Obtener los productos cada vez que refreshTable cambie
  useEffect(() => {
    const jwtToken = localStorage.getItem('jwtToken');
    fetch(`${process.env.REACT_APP_API_URL}/new-season`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${jwtToken}`, // Incluir el token en los encabezados
        'Content-Type': 'application/json'
      }
    })
    .then(response => {
      if (!response.ok) throw new Error('Error en la solicitud de productos');
      return response.json();
    })
    .then(data => {
      setProductos(data);
    })
    .catch(error => console.error('Error fetching products:', error));
  }, [refreshTable]); // Se vuelve a ejecutar cuando refreshTable cambie

  // Controlar la actualización de la tabla de productos al cerrar el modal de agregar
  const handleCloseAgregarModal = () => {
    setAgregarModalOpen(false);
    setRefreshTable(prev => !prev); // Alternar el estado para disparar la actualización
  };

  if (rango > 2 || rango < 1 || localStorage.getItem('jwtToken') == null) {
    return (<div>NOT FOUND</div>);
  }

  return (
    <ScrollShadow hideScrollBar className=' h-[100vh]' size={0}>
      <div
        className="h-[150px] bg-cover bg-center"
        style={{
          backgroundImage: `url(${empresa.banner})`,
        }}
      >
        <div className='relative'>
          <Avatar 
            src={empresa.fotoPerfil} 
            className="w-[100px] h-[100px] text-large top-[80px] ml-[10px] bg-white" 
          />
        </div>
      </div>
      <div className='p-[10px] pt-20'>
        <div className='grid grid-cols-2 grid-rows-1 align-middle'>
          <h4 className='text-left content-center font-semibold text-xl'>{empresa.nombre}</h4>
          <div className='flex'>
            <img 
              src='https://cdn-icons-png.flaticon.com/512/2040/2040531.png' 
              className='h-5 w-5 mt-auto mb-auto ml-auto cursor-pointer'
              onClick={() => setPerfilModalOpen(true)} // Abre el modal de Perfil
            />
            <img 
              src='https://cdn-icons-png.flaticon.com/512/2961/2961520.png' 
              className='h-8 w-8 mt-auto mb-auto ml-[10px] mr-[10px] cursor-pointer'
              onClick={() => setAgregarModalOpen(true)} // Abre el modal de Agregar
            />
          </div>
        </div>

        <div className='pt-6 text-center'>
          <ProductTable data={productos} />
        </div>
        <div>
          <Usuario/>
        </div>
      </div>

      {/* Modal para ver/editar perfil */}
      <ModalPerfil 
        isOpen={isPerfilModalOpen} 
        closeModal={() => setPerfilModalOpen(false)} 
      />

      {/* Modal para agregar producto */}
      <ModalAgregar 
        isOpen={isAgregarModalOpen} 
        closeModal={handleCloseAgregarModal} // Al cerrar, actualizará la tabla
      />
    </ScrollShadow>
  );
};

export default Empresa;

import { Button, ButtonGroup, Card, CardBody, CardFooter, CardHeader, Divider, Switch, Tab, Tabs } from '@nextui-org/react';
import React, { useState } from 'react';

const Wsp = () => {

  return (
    <div className=' grid grid-cols-3 grid-rows-1'>
        <div className=' col-span-2'>
        <Card className=' p-[10px]'>
            <CardHeader className=' text-xl font-semibold'>
                Enlace whatsapp
            </CardHeader>
            <CardBody className=' grid grid-cols-3 grid-rows-1'>
                <div className='col-span-2 p-[10px] flex items-center justify-center h-full'>
                    <div className='p-[10px] flex flex-col w-full items-center'>
                        <Button color="success" className='text-white font-semibold mb-3 w-full'>CONECTAR</Button>
                        <Button color="danger" className='font-semibold w-full'>CERRAR</Button>
                    </div>
                </div>
                <div className=' col-span-1'>
                    <Card className=' w-64 h-64' shadow="sm">
                        <CardBody>
                            <img src="https://upload.wikimedia.org/wikipedia/commons/d/d7/Commons_QR_code.png"></img>
                        </CardBody>
                    </Card>
                </div>
            </CardBody>
        </Card>
        </div>
    </div>
  );
};

export default Wsp;
